import React, { useState, useEffect } from 'react';
import "./lhf.css";
import NavBar from "../../../components/navbar";
//import vote from "../../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../../../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../../../components/footerbar";
import { Helmet } from "react-helmet";

export default function InfrastructureRedevelopmentProjects() {

  useEffect(() => { window.scrollTo(0, 0); }, []);

  const Slideshow = ({ slides, autoplay = true, autoplayInterval = 3000 }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
      if (autoplay) {
        const interval = setInterval(() => {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, autoplayInterval);

        return () => clearInterval(interval);
      }
    }, [autoplay, autoplayInterval, slides.length]);

    const goToPrevSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    const goToNextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    return (
      <div className="slideshow-container">
        <div className="controls">
          <button className="prev-button" onClick={goToPrevSlide}>
            &lt;
          </button>

          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${((currentSlide + 1) / slides.length) * 100}%`,
              }}
            ></div>
          </div>

          <button className="next-button" onClick={goToNextSlide}>
            &gt;
          </button>
        </div>

        <div className="slide">
          <img src={slides[currentSlide].imageUrl} alt={slides[currentSlide].caption} />
        </div>
      </div>
    );
  };

  const slides = [
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-04/1.jpg",
      caption: "Slide 1",
    },
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-04/2.jpg",
      caption: "Slide 2",
    },
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-04/3.jpg",
      caption: "Slide 3",
    },
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-04/4.jpg",
      caption: "Slide 4",
    },
    {
      imageUrl:
        "https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/post-04/5.jpg",
      caption: "Slide 5",
    },
  ];

  return (
    <>
      <div style={{ backgroundColor: "#1f1f1f" }}>
        <style>
          {`
            /* Customize scrollbar */
            ::-webkit-scrollbar {
              width: 3px;
              background-color: #1f1f1f;
            }

            ::-webkit-scrollbar-thumb {
              background-color: #0056b3;
              background-clip: content-box;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: #0056b3;
            }

            ::-webkit-scrollbar-track {
              background-color: #1f1f1f;
            }
          `}
        </style>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Infrastructure Redevelopment Projects | Lalan Group Sri Lanka
          </title>
          <meta
            name="title"
            content="Infrastructure Redevelopment Projects | Lalan Group Sri Lanka"
          />
          <meta
            name="description"
            content="We also contribute to the development of infrastructure in the surrounding villages. Child development centers, child care creches, and medical dispensaries are maintained and redeveloped by the foundation constantly. "
          />
          <meta
            name="keywords"
            content="Infrastructure Redevelopment Projects"
          />
          <link
            rel="canonical"
            href="https://www.lalangroup.com/sustainability/lalith-hapangama-foundation/infrastructure-redevelopment-projects/"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Infrastructure Redevelopment Projects - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/sustainability/lalith-hapangama-foundation/infrastructure-redevelopment-projects/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>
        <NavBar />
        <br />
        <div className="sub-icon">
          <a href="javascript:history.back()">
            <img
              src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/back.png"
              alt="back"
              style={{
                borderRadius: "50px",
                textAlign: "left",
                marginRight: "100px",
                color: "white",
                height: "50px",
                position: "absolute",
                marginTop: "40px",
                marginLeft: "40px",
              }}
            />
          </a>
        </div>
        <div className="sub-para">
          <p className="p-size-1">Infrastructure Redevelopment Projects</p>
          <p className="p-size-2">
            Whilst looking after the welfare of the communities on our estates,
            we also actively contribute to the redevelopment of infrastructure
            in the surrounding villages; one such initiative was the complete
            renovation and restoration of a dilapidated suspension bridge, which
            serves many of the adjoining villages and hamlets.
            <br />
            <br />
            Child development centers, child care creches, and medical
            dispensaries are maintained by us and renovated on a regular basis.
            Additionally, in line with fostering a sense of community and team
            spirit, sports facilities were introduced and are maintained by us
            regularly. We support all Annual sports events and religious
            festivals organized for our employees.
          </p>
        </div>
        <br /> <br />
        <br /> <br />
        <div style={{ marginLeft: "100px", marginRight: "100px" }}>
          <Slideshow slides={slides} autoplay={true} autoplayInterval={3000} />
        </div>
        {/* BestWeb - start */}
        <div className="bestweb">
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* BestWeb - start */}
            {/* <div>
              <a
                href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                target="_blank"
              >
                <img className="logopic" src={vote} alt="logo" />
              </a>
            </div> */}
            {/* BestWeb - end */}
            {/* TopWeb - start */}
            <div>
              <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
                <img
                  style={{ marginLeft: "30px" }}
                  className="logopic-2"
                  src={TopWeb}
                  alt="logo"
                />
              </a>
            </div>
            {/* TopWeb - end */}
          </div>
        </div>
        <br /> <br />
        {/* BestWeb - end */}
        <Footer3CS />
      </div>
    </>
  );
}
